<script>
export default {
	props: {
		slidesQuantity: {
			required: true,
			type: Number,
		},
	},
	data() {
		return {
			isActive: 1,
		};
	},
	methods: {
		swipe() {
			this.$refs.slider?.scrollLeft >
			this.$refs.slider?.offsetWidth * 0.5
				? (this.isActive = this.slidesQuantity)
				: (this.isActive = 1);
		},
		scroll(target) {
			let space = target == 1 ? 80 : 0;
			this.$refs.slider?.scrollTo({
				top: 0,
				left: this.$refs.slide_0?.offsetLeft - space,
				behavior: "smooth",
			});
		},
	},
};
</script>

<template>
	<div
		class="relative overflow-x-hidden flex flex-col items-center"
	>
		<div @scroll="swipe" ref="slider" class="slider text-left w-full">
			<div
				v-for="(slide,key) in slidesQuantity"
				:key="slide"
				class="w-full text-md"
				:ref="`slide_${key}`"
			>
				<slot :name="slide"> </slot>
			</div>
		</div>
		<div
			class="absolute dots z-10 flex items-center justify-center w-full h-2"
			v-if="slidesQuantity > 1"
		>
			<a
				v-for="slide in slidesQuantity"
				:key="slide"
				@click="scroll(slide)"
				class="cursor-pointer w-2 h-full rounded-full mr-3"
				:class="isActive == slide ? 'bg-green' : 'bg-gray-dark'"
			></a>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.slider {
	display: flex;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	scroll-snap-points-x: repeat(300px);
	scroll-snap-type: mandatory;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		display: none;
	}

	> div {
		flex-shrink: 0;
		text-align: center;
	}
}
.dots {
	bottom: get-vh(32px);
}
</style>
